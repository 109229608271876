.btn-pill{
	border-radius: 2rem;
}

.btn-expanded{
	padding:0.75rem 1.5rem;
}

%btn-clip{
	position: relative;
	overflow: hidden;
	perspective:3px;
	background: var(--bg, $info);
	color: var(--color, #232323);

	&:before,
	&:after{
		content: '';
		width:100%;
		height: 100%;
		background: rgba(#000,.1);position: absolute;
		left:0;
		top:0;
		z-index:-1;
		clip-path: circle(var(--w, 0px) at var(--x,0) 50%);
		transition:all 0.3s linear;
	}

	&:after{
		--x:100%;
	}

	&:hover{
		color: var(--color, #FFF);
		--w: 150%;
	}
}

.btn-clip-info{
	@extend %btn-clip;
	--color:#FFF;
	--bg: #{$info};
}

.btn-clip-danger{
	@extend %btn-clip;
	--color:#FFF;
	--bg: #{$danger};
}

.btn-clip-success{
	@extend %btn-clip;
	--color:#FFF;
	--bg: #{$success};
}

.btn-clip-primary{
	@extend %btn-clip;
	--color:#FFF;
	--bg: #{$primary};
}


.btn-voltar{
	min-width: 180px;
}
