.paginacao{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	&.paginacao__start{
		justify-content: flex-start;
	}

	&.paginacao__center{
		justify-content: center;
	}

	&.paginacao__end{
		justify-content: flex-end;
	}

	&.paginacao__between{
		justify-content: space-between;
	}

	.page__item {
		display: inline-block;
		padding: 5px 10px;
		box-shadow: 0 0 5px rgba(#000,.4);
		color: inherit;
		margin:0 5px;

		& ~ *{
			min-width: calc(1.5em + 10px);
			text-align: center;
		}
	}

	a.page__item:hover{
		text-decoration: none;
		color: $primary;
		background: #eee;
	}

	span.page__item--active{
		background: $primary;
		color: color-contrast($primary);
	}
}
