.topo__navbar{


	@include media-breakpoint-up(xl) {
		width: map-get($container-max-widths, xl);
		padding-top:20px;
		padding-bottom: 40px;
	}

	@include media-breakpoint-only(lg) {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	@include media-breakpoint-up(lg) {
		padding-left: 15px;
		padding-right: 15px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
	}

	@include media-breakpoint-down(md) {
		position: fixed;
		left:0;
		top:0;
		bottom: 0;
		height: 100%;
		width: 250px;
		overflow: auto;
		background: #FFF;
		z-index: 997;
		transition: all 0.6s linear;
		box-shadow: 0 0 8px rgba(#000,.2);
		transform: translateX(var(--x, -260px));
		z-index:1000;

		&.topo__navbar--shown{
			--x: 0px;
		}
	}
}

.topo__navbar__nav{
	ul{
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;

		a {
			color: #000;

			&:hover{
				text-decoration: none;
			}
		}

		@include media-breakpoint-down(md) {
			display: block;

			a{
				display: block;
				padding:10px 15px;
			}

			.nav-link:not(.active):hover{
				background: #EEE;
				color: #000;
			}

			.nav-link.active,
			.nav-link:hover{
				background: #e50316;
				color: #FFF;
			}
		}
	}

	.login__ctrls{
		flex-wrap: wrap;
		color: #000;

		&:hover{
			color: #F8001C;
			text-decoration: none;
		}

		.icon__mask{
			margin-left: auto;
			margin-right: auto;
			background: currentcolor;
		}

		span{
			display: block;
			width: 100%;
			text-align: center;
		}
	}

	@include media-breakpoint-up(xl) {
		display: flex;

		ul{
			display: flex;
			margin-bottom: 0;
			padding-right: 50px;
			border-right: 1px solid #ccc;

			li + li{
				margin-left: 50px;
			}
		}

		li,
		a{
			display: flex;
		}

		a{
			align-items: center;
			border-bottom: 8px solid transparent;
			transition: all 0.3s linear;
		}

		.nav-link:hover,
		.nav-link.active{
			border-color: #F8001C;
			color: #F8001C;
		}

		.nav-link{
			padding:0;
		}

		.login__ctrls{
			margin-left: 50px;
		}
	}

	@include media-breakpoint-only(lg) {
		display: flex;

		ul{
			display: flex;
			margin-bottom: 0;
			padding-right: 20px;
			border-right: 1px solid #ccc;

			li + li{
				margin-left: 20px;
			}
		}

		li,
		a{
			display: flex;
		}

		.nav-link{
			align-items: center;
			border-bottom: 8px solid transparent;
			transition: all 0.3s linear;
			padding: 0;
		}

		.nav-link:hover,
		.nav-link.active{
			border-color: #F8001C;
			color: #F8001C;
		}

		.login__ctrls{
			margin-left: 20px;
		}
	}

	@include media-breakpoint-down(md) {
		.login__ctrls{
			flex-wrap: nowrap;
			display: flex;
			padding: 10px 15px 10px 5px;

			span{
				width: auto;
				flex-grow: 1;
				align-self: center;
				text-align: left;
				padding-left:10px;
			}
		}
	}
}

.topo__navbar__brand{
	@include media-breakpoint-up(lg) {
		margin-right: auto;
	}

	@include media-breakpoint-down(md) {
		padding: 30px 15px;
		text-align: center;
	}
}
