@import "_fontfaces";

body{
	font-family: 'montserratregular', sans-serif;
	color: #6f6f6f;
	font-size: 14px;
}

hgroup{
	font-size: 15px;
	margin-bottom: 1em;

	h1{
		font-size: 1em;
		color: #c41625;
		font-family: 'montserratbold', sans-serif;

		&:before{
			content:'';
			display: inline-block;
			width: toEm(4, 15);
			height:1em;
			vertical-align: text-top;
			background: currentColor;
			margin-right: 0.5em;
		}
	}

	h2{
		font-size: toEm(22,15);
		color: #272727;
		font-family: 'montserratextrabold', sans-serif;
	}
}
