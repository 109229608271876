.internas{
	padding-bottom: 50px;
}

.page__header{
	margin-bottom: 60px;
	padding-top: 30px;

	h1{
		font-size: 14px;
		color: #141414;
		overflow: hidden;
		line-height: 1.5;
		text-align: right;
		font-family: 'montserratbold', sans-serif;

		span{
			display: inline-block;
			position: relative;

			&:before{
				content:'';
				border-top: 2px solid #e4e4e4;
				width: 100vw;
				right: 200%;
				top: calc(50% - 1px);
				position: absolute;
			}
		}
	}
}

.filtro__categoria{
	.btn{
		@include button-variant(#ededed, #ededed);
		border-radius:0;
		white-space: nowrap;

		&:after{
			content: fa-content($fa-var-caret-down);
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			display: inline-block;
			margin-left: 10px;
		}
	}

	.dropdown-menu{
		min-width: 200px;
		width: 100%;
		border-radius: 0;
		margin-top: 10px;
		overflow: auto;
		font-size: 14px;
		max-height: 250px;
		box-shadow: 0 0 15px rgba(#000,.3);
		border:none;
		padding-top: 0;
		padding-bottom: 0;

		a{
			display: block;
			padding: 10px;
			color: inherit;

			&:hover{
				text-decoration: none;
			}
		}

		.active a{
			background: #F9001D;
			color: #FFF;
		}

		li:not(.active) a:hover{
			background: #EEE;
		}
	}
}


.noticias__page{


	.noticia__thumbnail{
		margin-bottom: 30px;
		display: grid;

		@include media-breakpoint-up(sm) {
			grid-template-columns: min-content 1fr;
			grid-column-gap: 24px;
		}

		@include media-breakpoint-only(md) {
			max-width: 540px;
			margin-right: auto;
			margin-left: auto;
		}

		@include media-breakpoint-down(xs) {
			grid-template-columns: 60px calc(100% - 75px);
			grid-column-gap: 15px;

			.nome{
				font-size: 16px;
			}
		}

		.data__publicacao{
			background: #c72c39;
		}

		.foto{
			position: relative;
			width: 100%;
			max-width: 157px;
			align-self: flex-start;
			overflow: hidden;

			&:before{
				content: '+';
				width: calc(100% - 20px);
				height: calc(100% - 20px);
				left: 10px;
				top: 10px;
				background: rgba(#000,.8);
				color: #FFF;
				position: absolute;
				z-index: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 30px;
				transition:all 0.3s linear;
				opacity:var(--opacity, 0);
				transform: scale(var(--scale,0)) rotate(var(--rotate, 180deg));
				border-radius: var(--radius,100%);
			}
		}
	}

	.data__publicacao{
		padding:10px;
		color: #FFF;
		margin-bottom: 1rem;
		font-family: 'montserratextrabold';

		&:before{
			content: fa-content($fa-var-calendar-alt);
			font-family: 'Font Awesome 5 Free';
			font-weight: 400;
			line-height: 1;
			display: inline-block;
			margin-right: 10px;
		}
	}

	.nome{
		font-size: 18px;
		color: #272727;
		font-family: 'montserratbold';
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.descricao{
		margin-bottom: 1rem;
		line-height:1.5;
		max-height:3em;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	.noticia__link{
		color: inherit;

		.nome,
		.descricao{
			transition: color 0.3s linear;
		}

		&:hover {
			text-decoration: none;
			--scale: 1;
			--opacity: 1;
			--rotate: 0deg;
			--radius: 0;

			.nome,
			.descricao{
				color: #008FCE;
			}
		}
	}
}




.post{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-column-gap: 30px;
	margin-bottom: 40px;

	.image-area,
	.text-area{
		margin-bottom: 30px;
	}

	.data__publicacao{
		padding:10px;
		color: #FFF;
		margin-bottom: 1rem;
		font-family: 'montserratextrabold';
		background: #F9001D;

		&:before{
			content: fa-content($fa-var-calendar-alt);
			font-family: 'Font Awesome 5 Free';
			font-weight: 400;
			line-height: 1;
			display: inline-block;
			margin-right: 10px;
		}
	}

	.title__post{
		font-size: 22px;
		color: #272727;
		font-family: 'montserratbold';
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: 1rem;
	}
}
