.md__form{
	position: relative;
	margin-top:1rem;

	&.has-error{
		--context: #{$danger};
	}

	&.has-warning{
		--context: #{$warning};
	}
}

.md__form__input {
	border-radius:0;
	border-color: transparent;
	padding-left: 0;
	padding-right: 0;
	background-color: transparent;
	color: var(--color);
	background-image: linear-gradient(to right, var(--default, #CCC) 0%,var(--default, #CCC) 33.33%,var(--context,$success) 33.33%,var(--context,$success) 100%);
	background-size:300% 3px;
	background-position: left bottom;
	background-repeat: no-repeat;
	transition: background;

	&::placeholder{
		color: transparent;
	}
}

.md__form.focus .md__form__input,
.md__form__input:focus,
.md__form__input:not(:placeholder-shown) {
	background-position: right bottom;
	background-color: transparent;
	border-color: transparent;
	outline: none;
	box-shadow: none;
	color: var(--color);

	& ~ .md__form__label{
		transform: translateY(toRem(-20));
		font-size: toRem(14);
	}
}

.md__form__label{
	position: absolute;
	font-size: 1rem;
	padding-top:calc(0.375rem + 1px);
	top: 0;
	transition: all 0.3s linear;
}
