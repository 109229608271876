@font-face {
    font-family: 'montserratblack';
    font-display: swap;
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot');
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratextrabold';
    font-display: swap;
    src: url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.eot');
    src: url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.svg#montserratextrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratbold';
    font-display: swap;
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot');
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratregular';
    font-display: swap;
    src: url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.eot');
    src: url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
