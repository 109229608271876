@import "_mobile__controls.scss";
@import "_topo__navbar.scss";

.topo{
	font-family: 'montserratblack', var(--font-family-sans-serif);

	@include media-breakpoint-up(xl) {
		font-size: 13px;

		&.topo__inicial{
			position: fixed;
			top:0;
			left: 0;
			right: 0;
			width: 100%;
			z-index: 1000;
			transition: all 0.3s linear;

			.topo__navbar{
				padding-bottom: 20px;
			}

			&.topo--fx{
				background: #f3f3f3;
				box-shadow:0 0 8px rgba(#000,.2);
			}
		}

		&.topo__internas{
			background: #f3f3f3;
			box-shadow:0 0 8px rgba(#000,.2);
			position: sticky;
			z-index: 999;
			top: 0;
		}
	}

	@include media-breakpoint-only(lg) {
		position: sticky;
		top: 0;
		z-index: 1000;
		background: #f3f3f3;

		&.topo--fx{
			box-shadow:0 0 8px rgba(#000,.2);
		}
	}

	@include media-breakpoint-down(sm) {
		padding-top: 70px;
	}
}
