.mobile__controls{
	display: flex;
	position: fixed;
	top:0;
	left: 0;
	right: 0;
	z-index: 997;
	width: 100%;
	box-shadow: 0 0 10px rgba(#000,.2);
	height: 70px;
	background: #FFF;

	@include media-breakpoint-up(lg) {
		transform: translateY(calc((100% + 180px) * -1));
	}

}

.mobile__controls__brand{
	padding: 5px 10px;
	width:110px;
}

.mobile__controls__btn{
	@include button-variant(#FFF, #FFF);
	border-radius: 0;
	margin-left: auto;

	& > *{
		pointer-events: none;
	}
}

.menu-backdrop{
	position: fixed;
	background: rgba(#000,.7);
	z-index: 998;
	left: 0;
	top: 0;
	right:0;
	bottom: 0;
	width: 100%;
	height: 100%;
	transition:all 0.6s linear;
	animation: fadeIn 0.6s linear;

	&.hide{
		opacity: 0;
	}
}
