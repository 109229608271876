.rodape:before{
	content:'';
	display: block;
	width: calc(100% - 30px);
	max-width: (map-get($container-max-widths, xl) - 30px);
	border-top:6px solid #e4e4e4;
	margin-left:auto;
	margin-right:auto;
}

.footer__contatos{
	padding-top:50px;
	padding-bottom:20px;
}
.segura__check{
	display: flex;

	input{
		margin-right: 10px;
		margin-top: 2px;
	}
	label{
		cursor:pointer;
	}
}
.footer__contato{
	font-size: 14px;
	color:#7d7d7d;

	.icon-area{
		margin-right: 10px;
	}

	.media-body{
		max-width: calc(100% - 38px);
	}

	.media-title{
		font-family: 'montserratextrabold';
		color: #000;
		list-style: none;

		span,
		a{
			display: inline-block;
			max-width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.rodape__cinza{
	background: #f2f2f2;

	.container{
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: repeat(2,1fr);
			grid-template-rows: repeat(3,auto);
			grid-column-gap: 30px;
		}

		@include media-breakpoint-down(md) {
			max-width: 100%;
		}
	}
}

.rodape__header{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-end;
	border-bottom: 1px solid #d1d1d1;
	margin-bottom: 20px;
	padding-top: 64px;

	& > * {
		margin-bottom: 20px;
	}

	@include media-breakpoint-up(lg) {
		grid-column: 1/2;
		grid-rows: 1/2;
	}

	@include media-breakpoint-down(sm) {
		.rodape__brand{
			text-align: center;
			width: 100%;
		}
	}
}

.rodape__socialMidia{
	a{
		font-size: 1rem;

		display: inline-flex;
		align-items: center;
		justify-content: center;

		width: toRem(40);
		height: toRem(40);

		border:1px solid currentcolor;
		border-radius: 50%;
		margin: 5px;

		color: #000;
		transition: color 0.3s linear;

		&:hover{
			text-decoration: none;
			color: #F9001D;
		}
	}

	@include media-breakpoint-down(sm) {
		text-align: center;
		flex-grow:1;
	}
}

.rodape__form{
	background-color: #0084C3;
	padding-top: 64px;
	padding-bottom: 64px;
	color: #FFF;
	--color: #FFF;
	--default: #FFF;
	--context: #85ff00;

	@include media-breakpoint-down(md) {
		margin-left:-15px;
		margin-right:-15px;
	}

	@include media-breakpoint-only(md) {
		padding-left:40px;
		padding-right:40px;
	}

	@include media-breakpoint-down(sm) {
		padding-left:15px;
		padding-right:15px;
	}

	&.has-success{
		--context: #85ff00;
	}

	h2{
		font-size: 22px;
		font-family: 'montserratblack';
		margin-bottom: 30px;
	}

	textarea{
		min-height: 90px;
	}

	.btn{
		@include button-variant(#FFF, #FFF);
		min-width: 150px;
		font-family: 'montserratblack';
	}

	@include media-breakpoint-up(lg) {
		grid-column: 2/3;
		grid-row: 1/4;
		padding-left: 40px;
		padding-right: 40px;
	}

	@include media-breakpoint-up(sm) {
		background-image: var(--lazy-bg);
		background-position: right top;
		background-size: cover;
	}
}

.lazy__embed{
	margin-bottom: 20px;
	text-align: center;
	margin-right: auto;
	margin-left: auto;

	@include media-breakpoint-up(lg) {
		grid-column: 1/2;
		grid-row: 2/3;
	}
}

.copyrights{
	padding-top: 20px;
	padding-bottom: 64px;
	border-top: 1px solid #d1d1d1;
	font-size: 12px;

	svg{
		width: toEm(44,12);
		height:2em;
	}

	a{
		color: currentColor;
		transition: color 0.3s linear;

		&:hover{
			color: #F8001D;
		}
	}

	@include media-breakpoint-up(lg) {
		grid-column: 1/2;
		grid-row: 3/4;
	}

	@include media-breakpoint-down(md) {
		text-align: center;
	}
}
.msg__cookies{
	background: #FFF;
	position: fixed;
	bottom: 15px;
	left: 50%;
	transform: translateX(-50%);
	padding: 15px;
	border-radius: 15px;
	box-shadow: 0 0 10px rgba(#000,.8);
	width: 100%;
	z-index: 999999999;
	border: 1px solid rgba(#000,.2);

  &.d-none{
    display: none;
  }

	a{
		color: #0084C3;
		text-decoration: underline;
	}

	.btn{
		background-color: #0084C3;
		font-weight: bold;
    color: #fff;

    &:hover{
      background-color: darken(#0084C3, 10%);
    }
	}

	@media (max-width: 767px){
		max-width: calc(100% - 30px);
		font-size: 12px;
		text-align: center;

		a{
			display: inline-block;
		}

		.content{
			margin-bottom: 10px;
		}
	}

  @media (min-width: 767px){
		display: grid;
		grid-template-columns: 1fr min-content;
		grid-column-gap: 10px;
		align-items: center;

		.btn{
			white-space: nowrap;
		}
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		max-width: 768px;
	}

  @media (min-width: 992px) and (max-width: 1199.98px) {
		max-width: 1024px;
	}

  @media (min-width: 1200px) {
		max-width: 1280px;
	}
}
