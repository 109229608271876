#banner{
	width: 100%;
	max-width: 1920px;
	margin-left:auto;
	margin-right:Auto;
	overflow: hidden;
	position: relative;

	.carousel-inner{
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		z-index: 0;

		&:after{
			content:'';
			position: absolute;
			width: 100%;
			padding-bottom: percentage(131/1920);
			background: url(../images/banner-mask.png) center bottom repeat-x;
			background-size: auto 100%;
			z-index:2;
			left: 0;
			bottom: 0;
		}
	}

	.controles{
		position: absolute;
		left:50%;
		transform: translateX(-50%);
		padding-bottom: calc(#{percentage(131/1920)} + 15px);
		text-align: center;
		bottom:0;

		a{
			color: #000;
			font-size:30px;

			&:hover{
				text-decoration: none;
				color: #F8001C;
			}
		}

		a:not(.hide){
			display: inline-block;
			padding:0.325rem 0.75rem;
			margin: 0 0.5rem;
		}
	}

}


.section-wrapper{
	overflow: hidden;
	padding-top: 50px;
	position: relative;
	perspective:3;

	.qm__somos{
		position: relative;
		background: #FFF;

		.text__area{
			padding:20px;
		}

		&:before{
			content:'';
			width: 100vw;
			height:600vh;
			background: #1780bc;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%);
			z-index: -1;
		}

		.image__area{
			text-align: center;
		}

		@include media-breakpoint-up(lg) {
			display: flex;

			.image__area{
				max-width: 50%;
			}

			.text__area{
				flex-grow: 1;
			}
		}
	}

	.beneficios{
		color: #FFF;
		padding-top: 35px;
		padding-bottom: 55px;
		text-align: center;

		hgroup{
			h2{
				color: #FFF;
			}

			h1{
				color: #72d1ff;
			}
		}
	}

	.beneficio__icon{
		width: 60px;
		margin-left:auto;
		margin-right:auto;
		margin-bottom: 1em;
	}

	.beneficio__nome{
		font-family: 'montserratbold', sans-serif;
	}

	.owl-nav{
		margin-top: 30px;
		font-size: 30px;

		& > *{
			margin:0 10px;
		}
	}
}

#servicos{
	text-align: center;
	padding-top: 70px;
}

#owlServicos{
	padding-left:30px;
	padding-right:30px;

	.owl__servico{
		display: inline-block;
		transition: filter 0.3s linear;
		color: #0084BF;

		&:not(.active) {
			filter: grayscale(100%);
		}

		&:hover{
			filter: grayscale(0%);
			text-decoration: none;
		}
	}

	.owl__servico__icon{
		margin-bottom: 10px;
		width: 65px;
		margin-left:auto;
		margin-right:auto;
	}

	.owl-prev,
	.owl-next{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		font-size: 30px;
		color: #000;
		outline: none;

		&.disabled{
			color: #777;
		}
	}

	.owl-prev{
		left: 0;
	}

	.owl-next{
		right: 0;
	}
}

#owl__servico__description {
	text-align: left;
	@include clearfix;

	.text__area{
		background: #0084BF;
		color: #FFF;
		padding: 30px;
	}

	.text__area,
	.image__area{
		animation: fadeIn 1s linear;
	}

	h3{
		font-size: 17px;
	}

	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: repeat(12, calc(100% / 12));
		grid-template-rows: 40px auto auto;

		.text__area{
			grid-column: 9/13;
			grid-row: 1/3;
			position: relative;
			z-index: 5;
		}

		.image__area{
			grid-column: 1/11;
			grid-row: 2/4;
		}
	}
}

#blog{
	padding-bottom: 50px;

	.noticia__principal{
		width: 100%;
		max-width: 540px;
		margin-left:auto;
		margin-right: auto;
		margin-bottom: 30px;

		.data__publicacao{
			background: #1A1A1A;
		}

	}

	.noticia__thumbnail{
		margin-bottom: 30px;
		display: grid;

		@include media-breakpoint-up(sm) {
			grid-template-columns: min-content 1fr;
			grid-column-gap: 24px;
		}

		@include media-breakpoint-only(md) {
			max-width: 540px;
			margin-right: auto;
			margin-left: auto;
		}

		@include media-breakpoint-down(xs) {
			grid-template-columns: 60px calc(100% - 75px);
			grid-column-gap: 15px;

			.nome{
				font-size: 16px;
			}
		}

		.data__publicacao{
			background: #c72c39;
		}

		.foto{
			position: relative;
			width: 100%;
			max-width: 157px;
			align-self: flex-start;
			overflow: hidden;

			&:before{
				content: '+';
				width: calc(100% - 20px);
				height: calc(100% - 20px);
				left: 10px;
				top: 10px;
				background: rgba(#000,.8);
				color: #FFF;
				position: absolute;
				z-index: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 30px;
				transition:all 0.3s linear;
				opacity:var(--opacity, 0);
				transform: scale(var(--scale,0)) rotate(var(--rotate, 180deg));
				border-radius: var(--radius,100%);
			}
		}
	}

	.data__publicacao{
		padding:10px;
		color: #FFF;
		margin-bottom: 1rem;
		font-family: 'montserratextrabold';

		&:before{
			content: fa-content($fa-var-calendar-alt);
			font-family: 'Font Awesome 5 Free';
			font-weight: 400;
			line-height: 1;
			display: inline-block;
			margin-right: 10px;
		}
	}

	.nome{
		font-size: 18px;
		color: #272727;
		font-family: 'montserratbold';
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.descricao{
		margin-bottom: 1rem;
		line-height:1.5;
		max-height:3em;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	a:not(.btn){
		color: inherit;

		.nome,
		.descricao{
			transition: color 0.3s linear;
		}

		&:hover {
			text-decoration: none;
			--scale: 1;
			--opacity: 1;
			--rotate: 0deg;
			--radius: 0;

			.nome,
			.descricao{
				color: #008FCE;
			}
		}
	}

	a.saiba{
		color: #d01c2b;
		font-family: 'montserratextrabold';

		&:hover{
			color: darken(#d01c2b,10%);
		}
	}
}
